import { debounce } from '/Foundation/Common/code/Views/Foundation/Common/Debounce.js'

const adjustHeroVideo = () => {
    let container = document.querySelector('.hero-component .img-background-layer');
    let video = container.querySelector('video');

    let containerHeight = container.clientHeight;
    let containerWidth = container.clientWidth;
    let dimensions = containerWidth / containerHeight;
    let scaleFactor = containerHeight > containerWidth ? 9 / 16 : 16 / 9;
    let scaling = (dimensions / scaleFactor) * 100;

    if (scaling > 100)
        video.style.height = `${scaling}%`;

};

window.addEventListener('resize', debounce(adjustHeroVideo));

adjustHeroVideo();